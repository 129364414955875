const Error404Image = () => {
  return (
    <svg
      width='367'
      height='280'
      viewBox='0 0 367 280'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_5064_16681)'>
        <path d='M189.543 0H177.571V277.477H189.543V0Z' fill='#D63D2C' />
        <path
          d='M304.124 47.7726L262.543 76.3829L262.259 76.5766H85.5137V18.9639H262.259L285.341 34.8456L304.124 47.7726Z'
          fill='#EA5949'
        />
        <path
          d='M100.303 147.355H277.048V89.7402H100.303L58.438 118.549L100.303 147.355Z'
          fill='#EA5949'
        />
        <path
          d='M236.402 89.6904C236.402 89.6904 216.2 143.505 87.7803 138.739L100.303 147.355H277.037V89.74L236.402 89.6904Z'
          fill='#D63D2C'
        />
        <path
          d='M277.046 95.874C277.046 95.874 262.547 145.064 208.486 147.034L277.046 147.355V95.874Z'
          fill='#EA5949'
        />
        <path
          d='M269.849 96.9274V140.168H102.541L71.1195 118.549L102.541 96.9274H269.846H269.849ZM272.212 94.5654H101.805L66.9531 118.549L101.805 142.53H272.215V94.5654H272.212Z'
          fill='white'
        />
        <path
          d='M244.008 18.9643C244.008 18.9643 213.933 73.053 85.5137 68.2984V76.5794H262.247L304.112 47.7636L262.247 18.9478L244.008 18.9643Z'
          fill='#D63D2C'
        />
        <path
          d='M304.117 47.7727L262.538 76.383L220.886 76.2578C259.548 74.8406 277.973 49.2914 285.334 34.8457L304.117 47.7727Z'
          fill='#EA5949'
        />
        <path
          d='M260.016 26.1515L291.438 47.7705L260.016 69.3918H92.7106V26.1515H260.016ZM260.752 23.7896H90.3447V71.7537H260.752L295.599 47.7705L260.745 23.7896H260.752Z'
          fill='white'
        />
        <path
          d='M189.543 76.5791H177.571V81.7518H189.543V76.5791Z'
          fill='#7C2920'
        />
        <path
          d='M189.543 147.306H177.571V152.478H189.543V147.306Z'
          fill='#7C2920'
        />
        <path
          d='M189.543 147.306H184.879V277.478H189.543V147.306Z'
          fill='#7C2920'
        />
        <path
          d='M172.044 121.955C171.36 121.686 170.628 121.561 169.894 121.588H169.331V108.709C169.331 106.654 168.413 105.615 166.603 105.615C166.056 105.605 165.527 105.811 165.131 106.189C164.598 106.725 164.109 107.304 163.669 107.92L154.726 119.867C154.477 120.193 154.268 120.467 154.101 120.691C153.929 120.927 153.763 121.163 153.604 121.399C153.454 121.627 153.329 121.87 153.233 122.125C153.149 122.352 153.106 122.591 153.105 122.833C153.088 123.223 153.155 123.611 153.303 123.972C153.451 124.333 153.676 124.658 153.962 124.924C154.522 125.434 155.369 125.691 156.474 125.691H164.786V128.662C164.786 129.543 164.996 130.221 165.408 130.684C165.622 130.906 165.879 131.082 166.163 131.204C166.447 131.325 166.752 131.388 167.061 131.389C167.369 131.391 167.675 131.331 167.96 131.213C168.245 131.094 168.504 130.92 168.72 130.701C169.13 130.25 169.338 129.565 169.338 128.662V125.691H170.206C171.091 125.691 171.77 125.531 172.224 125.219C172.678 124.907 172.934 124.368 172.934 123.662C172.924 122.791 172.629 122.219 172.044 121.955ZM164.786 112.452V121.588H158.012L164.786 112.452Z'
          fill='white'
        />
        <path
          d='M189.308 108.012C188.587 107.279 187.721 106.705 186.765 106.326C185.739 105.931 184.647 105.736 183.547 105.749C181.909 105.682 180.292 106.137 178.93 107.049C177.569 107.962 176.534 109.283 175.976 110.823C175.553 111.966 175.266 113.155 175.122 114.366C174.933 115.893 174.843 117.43 174.853 118.969C174.844 120.203 174.923 121.436 175.089 122.658C175.235 123.726 175.496 124.774 175.868 125.786C176.488 127.42 177.572 128.839 178.986 129.869C180.269 130.763 181.781 131.274 183.344 131.341C184.908 131.408 186.458 131.028 187.813 130.247C189.078 129.486 190.123 128.408 190.844 127.12C191.474 125.982 191.902 124.745 192.11 123.462C192.355 121.881 192.469 120.283 192.45 118.683C192.45 115.282 192.015 112.613 191.154 110.745C190.7 109.735 190.076 108.811 189.308 108.012ZM180.181 123.641C179.907 121.935 179.783 120.208 179.812 118.48C179.812 115.41 180.1 113.1 180.67 111.631C181.241 110.161 182.192 109.505 183.616 109.505C184.595 109.505 185.374 109.824 185.923 110.45C186.472 111.075 186.893 112.065 187.134 113.338C187.408 114.996 187.531 116.675 187.503 118.355C187.532 120.105 187.414 121.854 187.151 123.584C186.914 124.888 186.519 125.899 185.953 126.593C185.388 127.288 184.64 127.611 183.649 127.611C183.223 127.633 182.798 127.555 182.406 127.386C182.015 127.216 181.668 126.959 181.392 126.634C180.829 125.958 180.42 124.947 180.181 123.641Z'
          fill='white'
        />
        <path
          d='M213.126 121.955C212.442 121.686 211.71 121.561 210.975 121.588H210.412V108.709C210.412 106.654 209.494 105.615 207.684 105.615C207.137 105.605 206.608 105.811 206.213 106.189C205.679 106.725 205.19 107.304 204.751 107.92L195.807 119.867L195.18 120.691C195.01 120.927 194.844 121.163 194.686 121.399C194.535 121.627 194.411 121.87 194.315 122.125C194.231 122.352 194.188 122.591 194.187 122.833C194.169 123.223 194.237 123.611 194.385 123.972C194.533 124.333 194.757 124.658 195.043 124.924C195.604 125.434 196.451 125.691 197.556 125.691H205.867V128.662C205.867 129.543 206.075 130.221 206.49 130.684C206.704 130.906 206.961 131.082 207.244 131.204C207.528 131.325 207.833 131.388 208.142 131.389C208.451 131.391 208.757 131.331 209.042 131.213C209.327 131.094 209.585 130.92 209.802 130.701C210.211 130.25 210.419 129.565 210.419 128.662V125.691H211.285C212.17 125.691 212.849 125.531 213.303 125.219C213.758 124.907 214.013 124.368 214.013 123.662C214.006 122.791 213.71 122.219 213.126 121.955ZM205.867 112.452V121.588H199.094L205.867 112.452Z'
          fill='white'
        />
        <path
          d='M147.73 41.9954H138.929V46.7193H147.032C147.628 46.7193 148.073 46.8523 148.366 47.1184C148.512 47.2524 148.627 47.4164 148.704 47.5991C148.78 47.7817 148.816 47.9788 148.809 48.1766C148.815 48.3754 148.78 48.5735 148.705 48.7578C148.63 48.9422 148.517 49.1087 148.373 49.2466C148.085 49.5237 147.638 49.6615 147.032 49.6599H138.929V55.1373H148.03C148.645 55.1373 149.107 55.279 149.419 55.5648C149.576 55.7084 149.699 55.8845 149.781 56.0807C149.863 56.2769 149.901 56.4886 149.892 56.7009C149.899 56.9091 149.86 57.1163 149.778 57.308C149.697 57.4998 149.574 57.6714 149.419 57.811C149.107 58.0944 148.645 58.2362 148.03 58.2362H137.414C136.565 58.2362 135.952 58.0496 135.578 57.6716C135.205 57.2937 135.02 56.6867 135.02 55.8458V41.3742C135.002 40.904 135.088 40.4356 135.271 40.0019C135.428 39.6563 135.706 39.3804 136.054 39.2271C136.485 39.0527 136.949 38.9722 137.414 38.9909H147.73C148.353 38.9909 148.815 39.1287 149.116 39.4043C149.266 39.5414 149.384 39.7093 149.463 39.8965C149.541 40.0837 149.578 40.2857 149.57 40.4884C149.579 40.693 149.542 40.8969 149.464 41.0861C149.385 41.2753 149.267 41.4453 149.116 41.5844C148.823 41.86 148.361 41.9969 147.73 41.9954Z'
          fill='white'
        />
        <path
          d='M158.244 50.0074H156.876V56.1934C156.876 57.0075 156.697 57.6074 156.337 57.9932C156.156 58.1858 155.935 58.337 155.69 58.4365C155.445 58.536 155.182 58.5814 154.917 58.5695C154.647 58.585 154.376 58.5389 154.125 58.4345C153.875 58.3302 153.651 58.1704 153.472 57.9672C153.128 57.5626 152.957 56.9713 152.959 56.1934V41.3792C152.959 40.5384 153.145 39.929 153.524 39.5534C153.902 39.1779 154.511 38.9889 155.353 38.9889H161.705C162.457 38.9828 163.208 39.0199 163.955 39.1C164.537 39.1631 165.105 39.3161 165.64 39.5534C166.243 39.8046 166.788 40.175 167.244 40.6423C167.708 41.1143 168.069 41.6773 168.304 42.2957C168.547 42.9313 168.67 43.6064 168.666 44.2868C168.666 45.7229 168.26 46.8692 167.45 47.7258C166.639 48.5824 165.409 49.191 163.759 49.5516C164.514 49.9701 165.187 50.5224 165.744 51.1813C166.373 51.8947 166.939 52.6613 167.436 53.4724C167.873 54.1751 168.26 54.9085 168.592 55.6667C168.87 56.3186 169.008 56.7674 169.006 57.013C169.003 57.2818 168.921 57.5437 168.77 57.7664C168.604 58.0184 168.375 58.2227 168.105 58.3593C167.802 58.5079 167.468 58.5816 167.13 58.5742C166.739 58.5884 166.353 58.4811 166.025 58.2672C165.723 58.0562 165.462 57.7911 165.257 57.4854C165.041 57.1689 164.75 56.7059 164.381 56.0918L162.815 53.4937C162.381 52.7217 161.878 51.9912 161.31 51.3112C160.945 50.8712 160.484 50.5212 159.962 50.2885C159.412 50.0874 158.829 49.992 158.244 50.0074ZM160.482 41.9178H156.876V47.2109H160.376C161.171 47.2276 161.965 47.1483 162.742 46.9747C163.305 46.8563 163.817 46.5667 164.208 46.1457C164.57 45.6899 164.75 45.1173 164.715 44.5372C164.728 44.0392 164.587 43.5493 164.312 43.1336C164.037 42.718 163.641 42.3966 163.177 42.213C162.721 42.0178 161.823 41.9193 160.482 41.9178Z'
          fill='white'
        />
        <path
          d='M177.623 50.0074H176.256V56.1934C176.256 57.0075 176.076 57.6074 175.716 57.9932C175.536 58.184 175.318 58.3342 175.075 58.4336C174.832 58.533 174.571 58.5793 174.309 58.5695C174.037 58.585 173.766 58.5389 173.515 58.4346C173.264 58.3302 173.041 58.1705 172.861 57.9672C172.518 57.5626 172.347 56.9713 172.347 56.1934V41.3792C172.347 40.5384 172.536 39.9298 172.913 39.5534C173.291 39.1755 173.899 38.9889 174.741 38.9889H181.089C181.841 38.9828 182.592 39.0199 183.339 39.1C183.92 39.1631 184.488 39.3161 185.021 39.5534C185.625 39.8052 186.171 40.1755 186.628 40.6423C187.091 41.1144 187.451 41.6774 187.685 42.2957C187.928 42.9313 188.051 43.6064 188.047 44.2868C188.047 45.7229 187.642 46.8692 186.831 47.7258C186.021 48.5824 184.79 49.191 183.14 49.5516C183.896 49.9707 184.569 50.5228 185.128 51.1813C185.756 51.8953 186.322 52.6618 186.819 53.4724C187.257 54.1751 187.644 54.9085 187.976 55.6667C188.254 56.3186 188.392 56.7674 188.39 57.013C188.388 57.282 188.305 57.5441 188.154 57.7664C187.988 58.0189 187.759 58.2234 187.489 58.3593C187.187 58.5082 186.853 58.5819 186.517 58.5742C186.125 58.5884 185.739 58.4811 185.412 58.2672C185.109 58.0562 184.847 57.7912 184.64 57.4854C184.428 57.1689 184.134 56.7059 183.767 56.0918L182.201 53.4937C181.767 52.7216 181.263 51.991 180.694 51.3112C180.329 50.8712 179.868 50.5212 179.346 50.2885C178.795 50.0863 178.21 49.9909 177.623 50.0074ZM179.859 41.9178H176.256V47.2109H179.755C180.55 47.2275 181.345 47.1482 182.121 46.9747C182.684 46.8557 183.196 46.5662 183.588 46.1457C183.947 45.689 184.127 45.117 184.094 44.5372C184.105 44.0343 183.962 43.54 183.685 43.12C183.409 42.7082 183.015 42.3894 182.554 42.2059C182.099 42.0154 181.201 41.9193 179.859 41.9178Z'
          fill='white'
        />
        <path
          d='M200.236 38.6602C202.238 38.6602 203.955 39.0648 205.387 39.8742C206.802 40.6646 207.939 41.8712 208.642 43.3297C209.381 44.8225 209.75 46.5751 209.75 48.5874C209.766 49.9644 209.562 51.3352 209.144 52.6477C208.767 53.8176 208.149 54.8958 207.329 55.8127C206.509 56.7143 205.491 57.415 204.355 57.8605C203.067 58.3532 201.696 58.5937 200.317 58.5691C198.931 58.5925 197.555 58.3454 196.264 57.8416C195.125 57.3924 194.104 56.6896 193.278 55.7867C192.457 54.8612 191.84 53.7731 191.468 52.5933C191.052 51.2902 190.846 49.9292 190.858 48.5615C190.843 47.1791 191.058 45.8038 191.494 44.4918C191.882 43.3223 192.512 42.2478 193.344 41.3386C194.164 40.4629 195.171 39.783 196.29 39.3499C197.55 38.8717 198.889 38.6376 200.236 38.6602ZM205.789 48.5615C205.818 47.3062 205.585 46.0587 205.105 44.8981C204.708 43.9361 204.026 43.1179 203.151 42.5527C202.276 42.0154 201.264 41.74 200.236 41.7591C199.493 41.7514 198.756 41.9011 198.074 42.1984C197.414 42.4908 196.829 42.9284 196.363 43.4785C195.846 44.1123 195.462 44.8432 195.232 45.6279C194.945 46.5788 194.805 47.5682 194.818 48.5615C194.805 49.5663 194.945 50.5674 195.232 51.5305C195.465 52.3364 195.863 53.0852 196.401 53.7294C196.876 54.2887 197.469 54.7357 198.138 55.038C198.925 55.3696 199.778 55.5136 200.63 55.4587C201.482 55.4037 202.31 55.1513 203.047 54.7215C203.931 54.1697 204.628 53.3646 205.046 52.4115C205.54 51.3675 205.788 50.0841 205.789 48.5615Z'
          fill='white'
        />
        <path
          d='M218.34 50.007H216.97V56.1929C216.97 57.007 216.791 57.6069 216.431 57.9927C216.25 58.1853 216.029 58.3365 215.784 58.436C215.539 58.5355 215.276 58.5809 215.011 58.569C214.741 58.5845 214.47 58.5384 214.219 58.434C213.969 58.3297 213.745 58.1699 213.566 57.9667C213.222 57.5621 213.051 56.9708 213.053 56.1929V41.3787C213.053 40.5379 213.239 39.9285 213.618 39.5529C213.996 39.1774 214.605 38.9884 215.447 38.9884H221.802C222.552 38.9824 223.303 39.0194 224.049 39.0995C224.631 39.1623 225.199 39.3153 225.734 39.5529C226.337 39.8047 226.883 40.175 227.34 40.6418C227.803 41.1145 228.163 41.6774 228.398 42.2952C228.641 42.9308 228.764 43.6059 228.76 44.2863C228.76 45.7224 228.354 46.8687 227.544 47.7253C226.733 48.5819 225.503 49.1905 223.853 49.5511C224.608 49.9702 225.282 50.5223 225.84 51.1808C226.469 51.8942 227.034 52.6608 227.53 53.4719C227.969 54.1736 228.356 54.9071 228.686 55.6662C228.964 56.3181 229.103 56.7669 229.103 57.0125C229.099 57.2813 229.017 57.5431 228.866 57.766C228.701 58.0184 228.471 58.2229 228.201 58.3588C227.898 58.5074 227.564 58.5811 227.227 58.5738C226.835 58.5879 226.449 58.4807 226.122 58.2667C225.819 58.0557 225.559 57.7906 225.353 57.4849C225.138 57.1684 224.847 56.7055 224.477 56.0913L222.914 53.4932C222.479 52.7216 221.974 51.9911 221.407 51.3108C221.042 50.8707 220.58 50.5207 220.058 50.288C219.508 50.0868 218.925 49.9914 218.34 50.007ZM220.576 41.9173H216.97V47.2104H220.47C221.265 47.2272 222.059 47.1479 222.836 46.9742C223.399 46.8564 223.911 46.5667 224.302 46.1452C224.664 45.6894 224.844 45.1168 224.809 44.5367C224.82 44.034 224.678 43.5397 224.402 43.1195C224.126 42.7077 223.732 42.3889 223.271 42.2054C222.817 42.0149 221.918 41.9188 220.576 41.9173Z'
          fill='white'
        />
        <path
          d='M283.734 274.955H81.0157C79.6398 274.955 78.5244 276.068 78.5244 277.442V277.513C78.5244 278.886 79.6398 280 81.0157 280H283.734C285.11 280 286.225 278.886 286.225 277.513V277.442C286.225 276.068 285.11 274.955 283.734 274.955Z'
          fill='#7C2920'
        />
        <path
          d='M84.8608 274.955H52.0338C50.6579 274.955 49.5425 276.068 49.5425 277.442V277.513C49.5425 278.886 50.6579 280 52.0338 280H84.8608C86.2367 280 87.3521 278.886 87.3521 277.513V277.442C87.3521 276.068 86.2367 274.955 84.8608 274.955Z'
          fill='#7C2920'
        />
        <path
          d='M36.9815 274.955H2.4913C1.11539 274.955 0 276.068 0 277.442V277.513C0 278.886 1.11539 280 2.4913 280H36.9815C38.3575 280 39.4728 278.886 39.4728 277.513V277.442C39.4728 276.068 38.3575 274.955 36.9815 274.955Z'
          fill='#7C2920'
        />
        <path
          d='M327.499 274.955H299.188C297.812 274.955 296.697 276.068 296.697 277.442V277.513C296.697 278.886 297.812 280 299.188 280H327.499C328.875 280 329.99 278.886 329.99 277.513V277.442C329.99 276.068 328.875 274.955 327.499 274.955Z'
          fill='#7C2920'
        />
        <path
          d='M364.509 274.955H342.974C341.598 274.955 340.483 276.068 340.483 277.442V277.513C340.483 278.886 341.598 280 342.974 280H364.509C365.885 280 367 278.886 367 277.513V277.442C367 276.068 365.885 274.955 364.509 274.955Z'
          fill='#7C2920'
        />
        <path
          d='M271.353 252.648H264.057V274.931H271.353V252.648Z'
          fill='#D63D2C'
        />
        <path
          d='M271.353 252.677H264.028V255.211H271.353V252.677Z'
          fill='#7C2920'
        />
        <path
          d='M310.417 193.375V228.301L285.679 252.998H250.694L225.956 228.301V193.375L250.694 168.679H285.679L310.417 193.375Z'
          fill='#EA5949'
        />
        <path
          d='M310.417 193.376V228.302L285.679 252.998H250.692L234.131 236.465C296.34 245.617 296.986 179.967 296.986 179.967L310.417 193.376Z'
          fill='#D63D2C'
        />
        <path
          d='M310.417 210.84C310.417 210.84 298.523 254.923 250.692 252.998H285.679L310.417 228.302V210.84Z'
          fill='#EA5949'
        />
        <path
          d='M306.312 195.074V226.606L283.978 248.903H252.395L230.061 226.606V195.074L252.395 172.777H283.978L306.312 195.074Z'
          stroke='white'
          stroke-width='7'
        />
        <path
          d='M271.353 252.648H268.424V274.931H271.353V252.648Z'
          fill='#7C2920'
        />
        <path
          d='M258.847 213.646C258.292 213.426 257.698 213.324 257.101 213.346H256.644V202.892C256.644 201.238 255.899 200.381 254.43 200.381C253.989 200.378 253.564 200.548 253.247 200.853C252.814 201.292 252.418 201.766 252.064 202.27L244.808 211.954C244.607 212.219 244.436 212.441 244.299 212.625C244.162 212.809 244.025 213.003 243.897 213.199C243.774 213.383 243.673 213.58 243.596 213.787C243.529 213.97 243.494 214.162 243.492 214.357C243.478 214.673 243.532 214.989 243.653 215.282C243.773 215.575 243.955 215.839 244.188 216.055C244.642 216.466 245.328 216.676 246.227 216.676H252.968V219.088C252.968 219.796 253.138 220.354 253.474 220.727C253.648 220.906 253.856 221.048 254.085 221.146C254.315 221.244 254.562 221.295 254.811 221.296C255.061 221.298 255.308 221.249 255.539 221.154C255.77 221.058 255.979 220.918 256.155 220.741C256.488 220.375 256.656 219.82 256.656 219.088V216.676H257.366C258.076 216.676 258.634 216.546 259.003 216.291C259.198 216.146 259.352 215.954 259.453 215.733C259.553 215.512 259.596 215.269 259.578 215.027C259.559 214.321 259.32 213.858 258.847 213.646ZM252.956 205.934V213.346H247.462L252.956 205.934Z'
          fill='white'
        />
        <path
          d='M272.853 202.332C272.268 201.738 271.564 201.272 270.788 200.965C269.958 200.643 269.075 200.483 268.185 200.492C266.855 200.435 265.541 200.803 264.434 201.543C263.328 202.283 262.487 203.356 262.034 204.607C261.689 205.534 261.456 206.499 261.341 207.481C261.186 208.721 261.113 209.969 261.121 211.218C261.116 212.219 261.182 213.219 261.319 214.21C261.439 215.076 261.65 215.926 261.951 216.747C262.457 218.071 263.336 219.219 264.483 220.054C265.592 220.847 266.925 221.267 268.289 221.254C269.468 221.265 270.628 220.955 271.644 220.358C272.671 219.741 273.518 218.867 274.102 217.822C274.613 216.899 274.96 215.896 275.129 214.855C275.329 213.572 275.421 212.275 275.406 210.977C275.406 208.22 275.051 206.052 274.353 204.538C273.982 203.723 273.475 202.977 272.853 202.332ZM265.446 215.013C265.224 213.629 265.125 212.228 265.147 210.826C265.147 208.329 265.384 206.458 265.843 205.268C266.302 204.078 267.078 203.534 268.233 203.534C268.582 203.516 268.932 203.575 269.256 203.708C269.58 203.841 269.87 204.043 270.106 204.302C270.563 204.824 270.894 205.613 271.088 206.647C271.311 207.992 271.411 209.354 271.389 210.717C271.412 212.14 271.317 213.562 271.102 214.969C270.916 216.024 270.589 216.858 270.13 217.408C269.897 217.666 269.613 217.872 269.295 218.015C268.978 218.157 268.635 218.232 268.287 218.235C267.939 218.238 267.595 218.169 267.275 218.033C266.955 217.896 266.668 217.695 266.43 217.441C265.973 216.891 265.642 216.079 265.446 215.013Z'
          fill='white'
        />
        <path
          d='M292.176 213.646C291.621 213.426 291.026 213.324 290.43 213.346H289.973V202.892C289.973 201.238 289.228 200.381 287.759 200.381C287.318 200.378 286.893 200.548 286.576 200.853C286.143 201.292 285.747 201.766 285.393 202.27L278.137 211.954C277.935 212.219 277.765 212.441 277.628 212.625C277.491 212.809 277.356 213.003 277.228 213.199C277.104 213.382 277.002 213.58 276.925 213.787C276.858 213.97 276.823 214.162 276.821 214.357C276.807 214.673 276.861 214.989 276.982 215.282C277.102 215.575 277.284 215.839 277.517 216.055C277.973 216.466 278.659 216.676 279.556 216.676H286.299V219.088C286.299 219.796 286.467 220.354 286.803 220.727C286.977 220.906 287.185 221.048 287.415 221.146C287.645 221.244 287.892 221.295 288.141 221.296C288.391 221.298 288.639 221.249 288.87 221.154C289.101 221.058 289.31 220.918 289.486 220.741C289.817 220.375 289.985 219.82 289.985 219.088V216.676H290.695C291.405 216.676 291.963 216.546 292.332 216.291C292.527 216.146 292.683 215.954 292.784 215.733C292.885 215.512 292.928 215.269 292.909 215.027C292.89 214.321 292.649 213.858 292.176 213.646ZM286.287 205.934V213.346H280.791L286.287 205.934Z'
          fill='white'
        />
        <path
          d='M127.331 268.811H81.2549L82.0948 265.781L83.7296 259.864L84.7233 256.281L86.1831 251L89.0032 240.817L90.463 235.536L100.738 198.404H107.848L111.614 212.013L118.123 235.536L119.585 240.817L122.403 251L123.865 256.281L126.493 265.781L127.331 268.811Z'
          fill='#EA5949'
        />
        <path
          d='M127.331 268.809H81.2476L82.0851 265.781L83.7223 259.864C115.83 257.975 111.605 212.014 111.605 212.014L126.484 265.781L127.331 268.809Z'
          fill='#D63D2C'
        />
        <path
          d='M136.759 268.809H71.8291V274.971H136.759V268.809Z'
          fill='#D63D2C'
        />
        <path
          d='M127.331 268.812H81.2549L82.0948 265.781H126.493L127.331 268.812Z'
          fill='#EA5949'
        />
        <path
          d='M119.575 240.817C119.575 240.817 118.61 261.147 95.2065 265.797L126.474 265.781L119.575 240.817Z'
          fill='#EA5949'
        />
        <path
          d='M119.585 240.817H89.0034L90.4632 235.536H118.123L119.585 240.817Z'
          fill='white'
        />
        <path
          d='M123.865 256.281H84.7231L86.1829 251H122.403L123.865 256.281Z'
          fill='white'
        />
        <path
          d='M121.738 268.809C121.738 268.809 113.133 275.297 81.2549 274.974H136.757V268.809H121.738Z'
          fill='#EA5949'
        />
      </g>
      <defs>
        <clipPath id='clip0_5064_16681'>
          <rect width='367' height='280' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Error404Image;
