import axios from 'axios';

import config from '../config';
import User from '../interfaces/User';


const { apiServerUrl } = config;

export const getUser = () => {
  return axios.get<User>(`${apiServerUrl}/admin/user`).then(({ data }) => data);
};
