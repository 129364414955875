import EnvironmentConfig from '../interfaces/EnvironmentConfig'

const developmentConfig: EnvironmentConfig = {
    dashboardUrl: 'http://localhost:5335',
    ruleServerUrl: 'http://localhost:3004',
    apiServerUrl: 'http://localhost:3007',
    casaWebAppUrl: 'http://localhost:3020',
    casaEcomUrl: 'https://ecom.casaqa.ajira.tech',
    casaBeatUrl: 'https://beat-api.casaqa.ajira.tech',
    turnedOffFeatures: [],
    keycloakConfig: {
        url: `https://auth.casa.ajira.tech/auth`,
        realm: `casa`,
        clientId: `casa_dashboard`,
    },
    ruleUiUrl: 'https://rules.casaqa.ajira.tech',
}

export default developmentConfig
