import { RouteObject } from "react-router-dom"
import PageGuard from "../guards/PageGuard"
import CreateTenantRoute from "../features/CreateTenant/routes"
import TenantListRoute from "../features/TenantListing/routes"
import DashboardLayout from "../layouts/Dashboard/Dashboard"
import {
    ApiDashboardRoute,
    IntegrationDashboardRoute,
    ServiceStatusRoute,
    ErrorDashboardRoute
} from "../features/OperationDashboard/routes";
const routesPages: RouteObject[] = [CreateTenantRoute, TenantListRoute, ApiDashboardRoute, IntegrationDashboardRoute, ServiceStatusRoute, ErrorDashboardRoute]

const Routes: RouteObject[] = [
  {
    path: "/",
    element: <DashboardLayout />,
    children : [
        {
            path: '/',
            element: <PageGuard />,
            children: routesPages,
        },
    ]
  },
]

export default Routes
