import { ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import App from './App'
import { AuthProvider } from './contexts/Auth'
import ConfirmDialog from './shared/components/ConfirmationDialog'
import theme from './theme'

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(
    <ThemeProvider theme={theme}>
        <AuthProvider>
            <App />
        </AuthProvider>
        <Toaster position="top-right" containerStyle={{ top: 80 }} />
        <ConfirmDialog />
    </ThemeProvider>
)
