import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ApiTabWrapper = lazy(
    () => import('../components/ApiTabWrapper')
);

const ErrorTabWrapper = lazy(
    () => import('../components/ErrorTabWrapper')
);

const IntegrationsTabWrapper = lazy(
    () => import('../components/IntegrationsTabWrapper')
);

const ServiceStatusWrapper = lazy(
    () => import('../components/ServiceStatusWrapper')
);

const ApiDashboardRoute: RouteObject = {
    path: 'api-insights',
    element: <ApiTabWrapper />,
}

const ErrorDashboardRoute: RouteObject = {
    path: 'error-insights',
    element: <ErrorTabWrapper />,
}

const IntegrationDashboardRoute: RouteObject = {
    path: 'integration-insights',
    element: <IntegrationsTabWrapper />,
}

const ServiceStatusRoute: RouteObject = {
    path: 'service-status',
    element: <ServiceStatusWrapper />,
}

export  { ApiDashboardRoute, IntegrationDashboardRoute, ServiceStatusRoute, ErrorDashboardRoute };
