import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const TenantListDashboardComponent = lazy (
    () => import('../components/TenantListingDashboard')
);

const TenantListRoute: RouteObject = {
    path: 'tenant-list',
    element: <TenantListDashboardComponent />,
}

export default TenantListRoute;