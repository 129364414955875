import { Close } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material'
import { create } from 'zustand'

type ConfirmDialogStore = {
    message: string
    onSubmit?: () => void
    close: () => void
}

const useConfirmDialogStore = create<ConfirmDialogStore>((set) => ({
    message: '',
    onSubmit: undefined,
    close: () => set({ onSubmit: undefined }),
}))

export const confirmDialog = (message: string, onSubmit: () => void) => {
    useConfirmDialogStore.setState({
        message,
        onSubmit,
    })
}

const ConfirmDialog = () => {
    const { message, onSubmit, close } = useConfirmDialogStore()
    return (
        <Dialog
            open={Boolean(onSubmit)}
            onClose={close}
            maxWidth="sm"
            fullWidth
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <DialogTitle>Confirm the action</DialogTitle>
                <IconButton onClick={close}>
                    <Close />
                </IconButton>
            </Box>
            <DialogContent>
                <Alert severity="error">{message}</Alert>
            </DialogContent>
            <DialogActions
                style={{
                    padding: '1rem',
                }}
            >
                <Button color="secondary" variant="contained" onClick={close}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        if (onSubmit) {
                            onSubmit()
                        }
                        close()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
