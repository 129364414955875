import _ from 'lodash';
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

import TenantBusiness from '../interfaces/TenantBusiness';
import UserType from '../interfaces/User';

const SESSION_BUSINESS_KEY = 'selectedBusinessId';

interface UserContextType {
  currentUser: UserType | null;
  businesses: TenantBusiness[];
  selectedBusiness: TenantBusiness | null;
  userPermissions: string[];
  businessUnitNameMap: { [key: string]: TenantBusiness };
  setBusiness: (businessId: number) => void;
}

const UserContext = createContext<UserContextType>({
  currentUser: null,
  businesses: [],
  selectedBusiness: null,
  userPermissions: [],
  businessUnitNameMap: {},
  setBusiness(businessId: number) {},
});

export const UserProvider: FC<
  React.PropsWithChildren & { currentUser: UserType }
> = ({ currentUser, children }) => {
  const businesses = useMemo(
    () =>
      currentUser.tenant.brands.reduce((acc, val) => {
        acc.push(...val.businesses);
        return acc;
      }, [] as TenantBusiness[]),
    [currentUser]
  );

  const [selectedBusiness, setSelectedBusiness] = useState(() => {
    const sessionBusinessId = getBusinessIDFromSession();
    if (sessionBusinessId) {
      const business = getBusinessById(sessionBusinessId);
      if (business) return business;
    }
    return businesses[0];
  });

  useEffect(() => {
    selectedBusiness.id !== getBusinessIDFromSession() &&
      saveBusinessIDInSession(selectedBusiness.id);
  }, [selectedBusiness]);

  const userPermissions = useMemo(() => {
    return (
      currentUser?.tenant_role_groups.reduce((acc, val) => {
        if (_.isArray(val.permissions)) {
          acc.push(...val.permissions);
          return acc;
        }

        const permissions = Object.values(val.permissions).reduce(
          (acc, { enabled }) => acc.concat(enabled),
          [] as string[]
        );

        acc.push(...permissions);

        return acc;
      }, [] as string[]) || []
    );
  }, [currentUser]);

  const businessUnitNameMap = useMemo(() => {
    return (
      currentUser?.tenant.brands.reduce((acc, val) => {
        val.businesses.forEach((business: TenantBusiness) => {
          acc[business.name] = business;
        });

        return acc;
      }, {} as { [key: string]: TenantBusiness }) || {}
    );
  }, [currentUser]);
  
  const handleBusinessChange = (businessId: number) => {
    const business = getBusinessById(businessId);

    if (!business) {
      throw new Error(`No Business found for id ${businessId}`);
    } else setSelectedBusiness(business);
  };

  function getBusinessById(businessId: number): TenantBusiness | undefined {
    return businesses.find(({ id }) => id === businessId);
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
        businesses,
        selectedBusiness,
        userPermissions,
        businessUnitNameMap,
        setBusiness: handleBusinessChange,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

const getBusinessIDFromSession = () => {
  const selectedBusiness = sessionStorage.getItem(SESSION_BUSINESS_KEY);
  return selectedBusiness ? +selectedBusiness : null;
};

const saveBusinessIDInSession = (businessId: number) => {
  return sessionStorage.setItem(SESSION_BUSINESS_KEY, businessId.toString());
};
