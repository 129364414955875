import { Components, Theme } from '@mui/material';
import palette from './palette';
import shadows from './shadows';

const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      sizeSmall: {
        padding: '.25rem .5rem',
        borderRadius: '.35rem',
      },
      sizeMedium: {
        padding: '.5rem 1rem',
      },
      sizeLarge: {
        padding: '.5rem 1rem',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
      },
      'body ::-webkit-scrollbar': {
        width: '8px',
      },

      'body ::-webkit-scrollbar-thumb': {
        borderRadius: '0.5rem',
        backgroundColor: palette.grey?.[200],
      },
      'body :hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.grey?.[300],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: '1.5px solid #CDD1DC',
      },
    },
  },
  MuiCard: {
    variants: [
      {
        props: { variant: 'elevation' },
        style: {
          boxShadow: shadows[1],
        },
      },
    ],
  },

  MuiCardContent: {
    styleOverrides: {
      root: {
        paddingBottom: '1rem !important',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      sx: {
        borderRadius: '.5rem',
      },
    },
  },
  MuiDialog: {
    variants: [
      {
        props: { maxWidth: 'md' },
        style: {
          maxWidth: `700px`,
          marginInline: 'auto',
        },
      },
    ],
    defaultProps: {
      BackdropProps: {
        sx: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgb(255 255 255 / 0%)',
        },
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
      size: 'medium',
    },
    variants: [
      {
        props: { variant: 'outlined', size: 'medium' },
        style: {
          '.MuiInputBase-input': {
            paddingBlock: '.7rem',
          },
          '.MuiFormLabel-root': {
            transform: 'translate(14px, 11px) scale(1)',
            '&.Mui-focused, &.MuiInputLabel-shrink': {
              transform: 'translate(14px, -9px) scale(.75)',
            },
          },
        },
      },
    ],
  },

  MuiTableHead: {
    defaultProps: {
      sx(theme) {
        return {
          backgroundColor: theme.palette.grey[100],
          '.MuiTableCell-head': {
            borderBottom: 'none',
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.grey[100],
          },
        };
      },
    },
  },

  MuiTable: {
    defaultProps: {
      sx(theme) {
        return {
          borderRadius: `.75rem`,
        };
      },
    },
  },

  MuiTableCell: {
    defaultProps: {
      sx(theme) {
        return {
          borderBottom: `1.5px solid ${theme.palette.grey[200]}`,
        };
      },
    },
  },
  MuiTableRow: {
    defaultProps: {
      sx() {
        return {
          '&:last-child .MuiTableCell-body': {
            borderBottom: 'none',
          },
        };
      },
    },
  },
  MuiAutocomplete:{
   variants:[{
    props:{
      size:'medium'
    },style:{
      // display:'none'
    }
   }]
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        maxHeight: 250,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      selected: ({ ownerState, theme }) => ({
        borderColor: ownerState.color,
      }),
    },
    defaultProps: {
      color: 'primary',
    },
    variants: [
      {
        props: {
          selected: true,
          color: 'primary',
        },
        style: ({ theme }) => ({
          borderColor: `${theme.palette.primary.main} !important`,
        }),
      },
    ],
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'capitalize',
      },
    },
  },
}

export default components;
