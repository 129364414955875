import { Box } from "@mui/material"
import { Suspense, useEffect } from "react"
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom"

import PageLoader from "../../shared/components/CasaLoader/CasaLoader"
import styles from "./Dashboard.module.scss"

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/tenant-list")
    // eslint-disable-next-line
  }, [])
  return (
    <Box id={styles.dashboard}>
      <Box className={styles.app}>
        {/* <Container component="main" className={styles.container}> */}
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
          {/* </Container> */}
      </Box>
      <ScrollRestoration />
    </Box>
  )
}

export default Dashboard
