import { Box, Button, Typography } from '@mui/material';
import styles from './Error404.module.scss';
import keycloak from '../../keycloak';
import Error404Image from '../../assets/images/Error404Image';

const Error404 = () => {

  return (
    <Box id={styles.error404}>
      <Box className={styles.error404Body}>
        <Box>
          <Error404Image />
        </Box>
        <Box className={styles.error404Info}>
          <Typography className={styles.error404InfoTitle} variant='h3'>
            Oops! Page Not Found
          </Typography>
          <Typography className={styles.error404InfoDescription}>
            You don't have permissions to access this site.<br />
            Please press the below button to logout.
          </Typography>
          <Button style={{ width: '200px', borderRadius: '2px'}} size='large' onClick={() => keycloak.logout()}>
            Log out
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Error404;
