import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CreateTenantComponent = lazy (
    () => import('../components/CreateTenant')
);

const CreateTenantRoute: RouteObject = {
    path: 'create-tenant',
    element: <CreateTenantComponent />,
}

export default CreateTenantRoute;