import EnvironmentConfig from '../interfaces/EnvironmentConfig'

const e2eQaConfigs: EnvironmentConfig = {
    dashboardUrl: 'https://dashboard.qa.casaretail.ai',
    ruleServerUrl: 'https://kapi.qa.casaretail.ai',
    apiServerUrl: 'https://kapi.qa.casaretail.ai', // TODO: Need to change
    casaWebAppUrl: 'https://web.qa.casaretail.ai',
    casaEcomUrl: 'https://ecom.qa.casaretail.ai',
    casaBeatUrl: 'https://beat-api.qa.casaretail.ai',
    turnedOffFeatures: [],
    keycloakConfig: {
        url: `https://qa.auth.ginesys.one/auth`,
        realm: `GinesysOne`,
        clientId: `GinesysOne`,
    },
    ruleUiUrl: 'https://rules.casaqa.ajira.tech',
}

export default e2eQaConfigs
